import React, { useRef, useContext, useState, useEffect } from 'react'
import { Global, css } from '@emotion/react'
import { Link, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import ReactDOM from 'react-dom'
import { graphql } from 'gatsby'
//import ReactModal from 'react-modal'
//import { ThemeProvider } from 'emotion-theming'
import { normalize } from 'normalize.css'
//import scrollToElement from 'scroll-to-element'
//import debounce from 'lodash.debounce'
import { globalHistory as history } from '@reach/router'
import { throttle } from 'lodash';

const { format, parseISO } = require('date-fns')
const { location, navigate } = history


// Theme
import theme from '../utils/theme';


// Context
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/globalContextProvider'

import { ScrollProvider } from '../utils/ScrollProvider'
import { SiteProvider, useSiteContext } from '../utils/SiteProvider'


// Components
// import Header from './header'
import HeaderNew from './header-new'
import Icon from './icon'
import Intro from './intro'
import FieldNote from './fieldNote'
import Container from '../components/container'
//import { ContrastHelper } from '../utils/styles'
import SelectedWorksLink from '../utils/selectedWorksLink'
import Scroll from '../components/scroll'
import Site from '../components/site'

import {PillStyle} from '../utils/styles'
import real100vh from '../utils/real100vh'


// Project Data
import projects from '../projects'


/*
 * Styles
 */
const Main = styled('main')`
  margin-top: -45px;

  @media ${theme.breakpoints.lg} { 
    margin-top: -50px;
  }
`


const Wrapper = styled('div')`
  position: relative;
  overflow: hidden;
`







const themes = {
  white: {
    foreground: "#000000",
    background: "#FFFFFF",
  },
  orange: {
    foreground: "#FFFFFF",
    background: "#E45A5A",
  },
}

const ThemedLayout = styled.div`
  color: ${props => themes[props.theme].foreground};
  background-color: ${props => themes[props.theme].background};
  //min-height: 100vh;
  min-height: var(--real100vh);
  transition: height 0.4s ease-in-out;
  overflow: hidden;
`




/*
const PillStyle = 
  css`
    margin-left: 8px;
    padding: 0 10px;
    color: inherit;
    text-decoration: none;
    font-size: 13px;

    display: inline-flex;
    align-items: center;
    height: 34px;
    border: 2px solid #000;
    border-radius: 27px;
    text-transform: uppercase;
    box-sizing: border-box;
    letter-spacing: 0.03em;
    user-select: none;
    margin-bottom: 8px;

    transition: all 0.1s ease-in-out;
    
    @media (hover:hover) {
      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    @media ${theme.breakpoints.md} { 
      letter-spacing: 0.02em;
      font-size: 16px;
      padding: 0 18px;
      height: 40px;
      margin-left: 10px;
    }
  `
*/

const Menu = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000;
  transform-origin: 0px 0px;
  transition: opacity 0.25s ease-in-out;
  opacity: ${props =>
    props.toggleMenu ? 1 : 0 };
  pointer-events: ${props =>
    props.toggleMenu ? 'auto' : 'none' };
`

const MenuInner = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 55px 9px 0;

  @media ${theme.breakpoints.lg} { 
    margin: 60px 15px 0;
    max-width: 83%;
  }
`

const PillGroup = styled('div')`
  //margin-left: -10px;
  //margin-right: 8px;
  //display: inline-flex;
`

const PillCategory = styled(Link)`
  ${PillStyle}
  display: inline-block;
  float: left;

  color: #fff;
  border-color: #fff;
  opacity: 0.3;
  margin: 0 4px 10px;
  overflow: hidden;

  &.active {
    background-color: #ffffff;
    color: #000000;
  }

  @media (hover:hover) {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  // Breakpoints
  @media ${theme.breakpoints.md} { 
    margin: 0 5px 10px;
  }
`

const WorksLink = styled(SelectedWorksLink)`
  ${PillStyle}
  display: inline-block;
  float: left;

  color: #fff;
  border-color: #fff;
  opacity: 0.3;
  margin: 0 4px 10px;
  overflow: hidden;

  &.active {
    background-color: #ffffff;
    color: #000000;
  }

  @media (hover:hover) {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  // Breakpoints
  @media ${theme.breakpoints.md} { 
    margin: 0 5px 10px;
  }
`

const PillLink = styled(Link)`
  ${PillStyle}
  display: inline-block;
  float: left;

  color: #fff;
  border-color: #fff;
  margin: 0 4px 10px;
  
  // Handle overflowing titles
  overflow: hidden;
  max-width: calc(100% - 8px);
  text-overflow: ellipsis;

  span {
    white-space: nowrap;
  }

  @media (hover:hover) {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  // Breakpoints
  @media ${theme.breakpoints.md} { 
    margin: 0 5px 10px;
  }
`

// const Site = styled('div')`
//   position: relative;
//   overflow: auto;
//   // height: 100vh;
//   height: var(--real100vh);
//   transition: height 0.4s ease-in-out;
// `


/*
 * Component
 */
const Layout = ({children}) => {


  // console.log(newSiteRef)
  // console.log(siteRef)

  const pathname = location.pathname // **will change with every location update**

  const siteRef = useSiteContext()
  // console.log(siteRef)

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext) || { theme: 'white', headroom: null }
  
  const [hideStatusContainer, setHideStatusContainer] = useState(true)

  const [splash, setSplash] = useState(true)
  const [removeIntro, setRemoveIntro] = useState(false)
  const [introFinished, setIntroFinished] = useState(false)

  const [lockScroll, setLockScroll] = useState(true)
  const [disableHeadroom, setDisableHeadroom] = useState(false)

  const [toggleMenu, setToggleMenu] = useState(false)


  const [scrollValue, setScrollValue] = useState(0)


  // Index specific
  const [currentProject, setCurrentProject] = useState(null)

  const [fieldNotes, setFieldNotes] = useState(null) 

  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, toggleModal] = useState(false);

  // About specific 
  const [isCurrent, setIsCurrent] = useState(null)
  const [isShortcut, toggleShortcut] = useState(false)

  // News specific
  const [newsTitles, setNewsTitles] = useState(null)


  const [initialPath, setInitialPath] = useState(null)
  const [section, setSection] = useState(null)



  // Lock Body Scroll when menu is open
  /*
  useEffect(() => {
    toggleMenu ? setLockScroll(true) : setLockScroll(false)
  }, [toggleMenu])
  */
  useEffect(() => {
    setInitialPath(pathname)
  }, [])


  useEffect(() => {
    setTimeout(() => {
      setSplash(false)
    }, 2000)
  },[])


  const real100vhThrottled = throttle(real100vh, 100);
  useEffect(() => {
    window.addEventListener('resize', real100vhThrottled)
  },[])



  function handleOpenModal(open) {
    open ? setLockScroll(true) : setLockScroll(false)
    fieldNotes ? setModalContent(fieldNotes) : null
    open ? toggleModal(true) : toggleModal(false)
  }


  const handleMenuClick = (event, project, shortcut) => {
    shortcut ? toggleShortcut(true) : null

    // Save Link
    const href = event.target.href
    console.log(href);

    // Don't follow Link
    event.preventDefault()

    // Go to link
    window.location = href;


    project ? setCurrentProject(project.title) : null
    project ? setFieldNotes(project.fieldNotes) : null
    setHideStatusContainer(false) // MAYBE SHOULD ONLY REVEAL STATUS CONTAINER IF IT HAS A VALUE?

    // Wait 100ms before closing so that Headroom can act on current menu status
    setTimeout(() => {
      // Close Modal
      modalIsOpen ? toggleModal(false) : null

      // Close Menu
      toggleMenu ? setToggleMenu(false) : null

      // Reset shortcut state
      isShortcut ? toggleShortcut(false) : null
    }, 100)
  }


  const handleMenuClickNew = (event, project, shortcut) => {
    shortcut ? toggleShortcut(true) : null
    
    // Don't follow Link
    // event.preventDefault()

    // Get target
    // const href = event.target.href
    
    /*
    if(project) {
      toggleShortcut(true)

      const target = document.getElementById(project.id)
      //const container = document.querySelector('.site')
      const container = siteRef.current

      container.scrollTop = target.offsetTop; 
    } else {
      window.location = href;
    }
    */

    if(project) {
      setCurrentProject(project.title)
      setFieldNotes(project.fieldNotes)
      setHideStatusContainer(false)
    } else {
      /*
      setHideStatusContainer(true)
      setFieldNotes(null)
      setCurrentProject(null)
      */
    }

   
    // Wait 100ms before closing so that Headroom can act on current menu status
    setTimeout(() => {
      // Close Modal
      modalIsOpen ? toggleModal(false) : null

      // Close Menu
      toggleMenu ? setToggleMenu(false) : null

      // Reset shortcut state
      setTimeout(() => {
        toggleShortcut(false)
      }, 50)
    }, 100)

  }






  /*
  const postIntro = debounce(offset => {
    window.removeEventListener('scroll', onScroll)
    setRemoveIntro(true)
    window.scrollTo(0, (offset - 100))
  }, 500);

  const onScroll = e => {
    if(window.pageYOffset > 100) {
      postIntro(window.pageYOffset)

      if (pathname == '/news/') {
        setDisableHeadroom(true)
      } else {
        setDisableHeadroom(false)
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, []);
  */





  const data = useStaticQuery(
    graphql`
      query {
        allSanityPost(sort: {publishedAt: DESC}, limit: 100) {
          edges {
            node {
              id
              publishedAt
              slug {
                current
              }
            }
          }
        }
      }
    `
  )




  // https://github.com/gatsbyjs/gatsby/issues/7505#issuecomment-436005039
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { 
      // openModal, 
      // modalIsOpen, 
      handleOpenModal,
      modalIsOpen,
      setModalContent, 
      // disableHeadroom, // remove?
      // setDisableHeadroom, // remove?
      setNewsTitles,
      removeIntro,
      setHideStatusContainer,
      hideStatusContainer,
      fieldNotes,
      setFieldNotes,
      setCurrentProject,
      setIsCurrent, // About
      isCurrent, // About
      setSection,
    })
  );

  // const siteRef = useSiteContext()
  // console.log(siteRef)

  return (
    <>
      <SiteProvider>
      <ScrollProvider>
      <ThemedLayout 
        theme={state.theme}
      >
        <Global
          styles={css`
            ${normalize}

            :root {
                --real100vh: 100vh;
            }

            html {
              box-sizing: border-box;
            }
            *, *:before, *:after {
              box-sizing: inherit;
            }

            html,
            body {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            body {
              font-family: Helvetica;
              background-color: #000000;
              overflow: hidden;
/* 
              overflow: scroll;
              height: 500vh; */
            
              /*
              ${(lockScroll === true || splash === true) && `
                overflow: hidden;
              `}
              */
            }
          `}
        />

        <Wrapper
          splash={splash}
        >

          { !removeIntro &&
            <Intro 
              splash={splash}
              setSplash={setSplash}
              removeIntro={removeIntro}
              setRemoveIntro={setRemoveIntro}
              setIntroFinished={setIntroFinished}
            />
          }

          <Site>
            <Menu toggleMenu={toggleMenu}>
              <MenuInner>
                <PillGroup>
                  <WorksLink
                    to='/'
                    //onClick={(e)=>{ setToggleMenu(!toggleMenu);  setHideStatusContainer(true); }}
                    onClick={ (e) => handleMenuClickNew(e) }
                    //activeClassName='active'
                  >
                    Selected Works
                  </WorksLink>
                  {
                    projects.map(project =>
                      <PillLink
                        key={project.id} // Should be generated unique ID
                        to={ `/${project.id}` }
                        onClick={ (event) => handleMenuClickNew(event, project, true) }
                      >
                        <span>{ project.title }</span>
                      </PillLink>
                    )
                  }
                </PillGroup>

                <PillGroup>
                  <PillCategory
                    to='/about'
                    //onClick={() => {setToggleMenu(!toggleMenu);  }}
                    onClick={ (e) => handleMenuClickNew(e) }
                    partiallyActive={true}
                    activeClassName='active'
                  >
                    About
                  </PillCategory>
                  <PillLink 
                    to='/about/biography'
                    //onClick={(e)=>{ handleLinkClick(e, '#biography'); setToggleMenu(!toggleMenu) }}
                    // onClick={(e)=>{ setToggleMenu(!toggleMenu);  }}
                    onClick={ (e) => handleMenuClickNew(e, null, true) }
                  >
                    Biography
                  </PillLink>
                  <PillLink 
                    to='/about/contact'
                    //onClick={(e)=>{ handleLinkClick(e, '#contact'); setToggleMenu(!toggleMenu) }}
                    //onClick={(e)=>{ setToggleMenu(!toggleMenu);  }}
                    onClick={ (e) => handleMenuClickNew(e, null, true) }
                  >
                    Contact
                  </PillLink>
                </PillGroup>

                <PillGroup>
                  <PillCategory
                    to='/news'
                    partiallyActive={true}
                    // onClick={() => { setToggleMenu(!toggleMenu); }}
                    onClick={ (e) => handleMenuClickNew(e) }
                    activeClassName='active'
                  >
                    News
                  </PillCategory>
                  {
                    data.allSanityPost.edges.map(({node}) => {
                      // console.log(node)
                      // const dateSegment = format(node.publishedAt, 'YYYY/MM')
                      // const path = `/news/${dateSegment}/${node.slug.current}/`
                      const slug = `/news/${node.slug.current}`

                      return (
                        <PillLink 
                          key={node.id}
                          to={slug}
                          onClick={(e)=>{ handleMenuClickNew(e) }}
                        >
                          {format(parseISO(node.publishedAt), 'dd.MM.yyyy')}
                        </PillLink>
                      )
                    })
                  }
                </PillGroup>
              </MenuInner>
            </Menu>

            <FieldNote 
              modalIsOpen={modalIsOpen} 
              handleClose={() => handleOpenModal(false)}
            >
              {modalContent}
            </FieldNote>


            <HeaderNew 
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
              // disableHeadroom={disableHeadroom} // temp
              currentProject={currentProject}
              fieldNotes={fieldNotes}
              handleOpenModal={handleOpenModal}
              modalIsOpen={modalIsOpen}
              hideStatusContainer={hideStatusContainer}
              newsTitles={newsTitles} // For News
              isCurrent={isCurrent} // For About
              // toggleMenu={toggleMenu} // Inform Headroom of Menu Status
              handleMenuClick={handleMenuClick}
              isShortcut={isShortcut}
              toggleShortcut={toggleShortcut}
              setHideStatusContainer={setHideStatusContainer}
              // siteRef={siteRef}
              //newRef={newRef}
              // handleInPageNavigation={handleInPageNavigation}
              introFinished={introFinished}
            />

            { /* (pathname != '/news/') &&
              <ContrastHelper /> */
            }

            <Main>
              <Scroll 
                initialPath={initialPath}
                section={section}
                // pathname={pathname}
                // siteRef={siteRef}
                splash={splash}
              >
                {childrenWithProps}
              </Scroll>
            </Main>
          </Site>

        </Wrapper>

      </ThemedLayout>
      </ScrollProvider>
      </SiteProvider>
    </>
  )
}

export default Layout
